import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { isNil } from "ramda";
import { compose } from "redux";

import { Table, Modal, Button, Input } from "antd";

import OfficeRow from "./row";
import InfoOffice from "../info";
import { FormattedMessage, injectIntl } from "react-intl";

import { canModifyData } from "../../../utils/get-roles";

import { ENABLE_GOV_CODE } from "../../../config";

const Search = Input.Search;

const locale = {
    emptyText: <FormattedMessage id="general.no-result" />
};
const columns = [
    {
        title: "general.description",
        type: "string",
        dataIndex: "base.details.description",
        key: "base.details.description"
    },
    {
        title: "general.creation-date",
        type: "date",
        dataIndex: "base.status.createdAt",
        key: "base.status.createdAt",
        width: "40%"
    },
    {
        title: "general.action",
        type: "actions",
        dataIndex: "Azioni",
        key: "Azioni"
    }
];

if (ENABLE_GOV_CODE === "enable") {
    columns.splice(1, 0, {
        title: "general.govCode",
        type: "string",
        dataIndex: "base.identifier.govCode",
        key: "base.identifier.govCode"
    });
}

class Offices extends Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        deleteOffice: PropTypes.func.isRequired,
        deleteOfficeStatus: PropTypes.object.isRequired,
        disableOfficeReset: PropTypes.func.isRequired,
        loadCompany: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            mDeleteOffice: false,
            mInfoOffice: false,
            officeSelect: null,
            searchText: ""
        };
    }

    componentDidUpdate(prevProps) {
        const { deleteOfficeStatus } = this.props;

        if (prevProps.deleteOfficeStatus.started && deleteOfficeStatus.ended) {
            this.setState({ mDeleteOffice: false });
        }
    }

    handleClose = modalToClose => {
        const { disableOfficeReset } = this.props;

        if (modalToClose === "mDeleteOffice") {
            disableOfficeReset();
        }

        this.setState({ [modalToClose]: false });
    };

    getData() {
        const { company } = this.props;
        const { searchText } = this.state;

        let officeData = [];
        let data =
            searchText !== ""
                ? company.layers.filter(function (lists) {
                      // check filter text on description or address
                      return (
                          lists.base.details.description.toLowerCase().includes(searchText.toLowerCase()) ||
                          (lists.base.details.addresses[0] &&
                              lists.base.details.addresses[0].fullAddress &&
                              lists.base.details.addresses[0].fullAddress
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase()))
                      );
                  })
                : company.layers;

        if (!isNil(data)) {
            data.forEach((value, index) => {
                if (value.base.details.classifier !== "INTERMEDIARY" && value.base.status.active) {
                    value.key = index;
                    officeData.push(value);
                }
            });
        }
        return officeData;
    }

    deleteOffice(officeSelect) {
        const { company, deleteOffice } = this.props;
        deleteOffice(company.base.id, officeSelect.base.id);
    }

    renderModalDeleteOffice() {
        const { deleteOfficeStatus, intl } = this.props;
        const { officeSelect } = this.state;
        return officeSelect !== null ? (
            <div>
                <Modal
                    cancelText={<FormattedMessage id="general.annulla" />}
                    okText={<FormattedMessage id="general.delete" />}
                    onCancel={() => this.handleClose("mDeleteOffice")}
                    visible={this.state.mDeleteOffice}
                    onOk={() => this.deleteOffice(officeSelect)}
                    style={{ top: "20%" }}
                    title={<FormattedMessage id="general.delete-office" />}
                >
                    <p>
                        {intl.formatMessage({
                            id: "c-registry-office.modal.create.delete-question-office"
                        }) + officeSelect.base.details.description}
                    </p>
                    <p style={{ color: "red" }}>
                        {deleteOfficeStatus.errorInfo ? deleteOfficeStatus.errorInfo.message : undefined}
                    </p>
                </Modal>
            </div>
        ) : null;
    }

    renderModalInfoOffice() {
        const { officeSelect } = this.state;
        return officeSelect !== null ? (
            <div>
                <Modal
                    footer={null}
                    onCancel={() => this.handleClose("mInfoOffice")}
                    style={{ top: "20%" }}
                    title={<FormattedMessage id="general.info-office" />}
                    visible={this.state.mInfoOffice}
                >
                    <InfoOffice info={officeSelect} type="office" />
                </Modal>
            </div>
        ) : null;
    }

    renderOffices() {
        const { company, match, user, intl } = this.props;
        let isEnable = !canModifyData(user, company.base.id) || company.base.status.status !== "VALIDATED";

        return (
            <div>
                <div className="table-operations" style={{ display: "flex" }}>
                    <Link to={`/${match.params.item}/registry/office/create`}>
                        <Button disabled={isEnable} type="primary" style={{ margin: "20px" }} size="large">
                            <FormattedMessage id="general.add" />
                        </Button>
                    </Link>
                    <Search
                        enterButton
                        style={{ margin: "20px" }}
                        size="large"
                        placeholder={intl.formatMessage({
                            id: "c-registry-office.modal.create.office-search"
                        })}
                        onSearch={value => this.setState({ searchText: value })}
                    />
                </div>
                <Table
                    dataSource={this.getData()}
                    locale={locale}
                    style={{
                        borderRadius: "5px",
                        background: "#fff",
                        margin: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px"
                    }}
                >
                    {columns.map((column, index) => (
                        <Table.Column
                            dataIndex={column.dataIndex}
                            key={index}
                            render={(text, row) => (
                                <OfficeRow
                                    value={row}
                                    onClick={row => this.onClick(row)}
                                    text={text}
                                    column={column}
                                    openEdit={() =>
                                        this.props.history.push(
                                            `/${match.params.item}/registry/office/${row.base.id}/edit`
                                        )
                                    }
                                    isEnable={
                                        isEnable
                                            ? {
                                                  status: false,
                                                  color: "rgba(0,0,0,.25)",
                                                  background: "#f7f7f7"
                                              }
                                            : {
                                                  status: true,
                                                  color: "#FFF",
                                                  background: "#11B7FF"
                                              }
                                    }
                                    openDelete={() =>
                                        this.setState({
                                            mDeleteOffice: true,
                                            officeSelect: row
                                        })
                                    }
                                    openInfo={() =>
                                        this.setState({
                                            mInfoOffice: true,
                                            officeSelect: row
                                        })
                                    }
                                />
                            )}
                            title={<FormattedMessage id={column.title} />}
                        />
                    ))}
                </Table>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderOffices()}
                {this.renderModalDeleteOffice()}
                {this.renderModalInfoOffice()}
            </div>
        );
    }
}

const composedHOC = compose(withRouter, injectIntl);

export default composedHOC(Offices);
