import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { ChildApp } from "@mondora/microfrontends";

import { COMMON_LOGIN_API_URL } from "../../../config";

const ChildAppComponent = ({
    serviceId,
    url,
    childOrigin,
    disableDataPropagation,
    onLaunched,
    suppliedMethods,
    launchData,
    launchingPlaceholder,
    className,
    itemId,
    managerId,
    deepLink = "/",
    deepLinkParameters = {}
}) => {
    const [isLaunching, setIsLaunching] = useState(!disableDataPropagation);
    const [isLoadingUrl, setIsLoadingUrl] = useState(serviceId && !disableDataPropagation ? true : false);
    const [origin, setOrigin] = useState(childOrigin);
    const iframeRef = useRef();

    useEffect(() => {
        if (serviceId && !disableDataPropagation) {
            const fn = async () => {
                const res = await fetch(`${COMMON_LOGIN_API_URL}/registry/services/${serviceId}`);
                const json = await res.json();
                if (res.status >= 400) {
                    return;
                }

                setOrigin(json.item.value);
                setIsLoadingUrl(false);
            };

            fn();
        }
    }, [disableDataPropagation, serviceId]);

    useEffect(() => {
        if (!disableDataPropagation) {
            const conn = new ChildApp({
                iframe: iframeRef.current,
                onLaunched,
                suppliedMethods,
                launchData,
                childOrigin: serviceId ? origin : undefined
            });

            conn.launch().then(setIsLaunching(false));

            return () => setIsLaunching(true);
        }
    }, [disableDataPropagation, origin, isLoadingUrl, launchData, onLaunched, suppliedMethods, serviceId]);

    const state = { ownerId: managerId || itemId, itemId: itemId };
    var deepLinkParameterArray = Object.entries(deepLinkParameters).map(
        ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
    deepLinkParameterArray.push("embed=true");

    const queryString = [
        `serviceId=${serviceId}`,
        `state=${btoa(JSON.stringify(state))}`,
        `deepLink=${deepLink}?${encodeURIComponent(deepLinkParameterArray.join("&"))}`
    ].join("&");

    return (
        <>
            {isLaunching || isLoadingUrl ? launchingPlaceholder : null}
            <iframe
                title="child-app"
                className={className}
                src={serviceId ? `${COMMON_LOGIN_API_URL}/jump?${queryString}` : url}
                ref={iframeRef}
            />
        </>
    );
};

ChildApp.propTypes = {
    url: PropTypes.string.isRequired,
    childOrigin: PropTypes.string,
    // Used to disable microfrontends communication
    disableDataPropagation: PropTypes.bool,
    onLaunched: PropTypes.func,
    suppliedMethods: PropTypes.objectOf(PropTypes.func),
    launchData: PropTypes.object,
    launchingPlaceholder: PropTypes.node,
    className: PropTypes.string,
    serviceId: PropTypes.string,
    // ID (V3) of the item
    itemId: PropTypes.string,
    // Id (V3) of the manager for this service. Used to set ownerId in initialData.
    // If not provided, itemId is used instead
    managerId: PropTypes.string,
    deepLinkParameters: PropTypes.object
};

export default ChildAppComponent;
