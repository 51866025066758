import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon, Spin } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import "./style.css";
import { getCompanies } from "../../actions/companies";
import { getConnection, findOwnManagedConnections } from "../../actions/connections/read";
import { fetchItemServices, resetFetchItemServices } from "../../actions/services/service-subscription-v2";
import { certificationInfo } from "../../actions/certification";
import { getExtendServiceMethod, getDeleteServiceExtensionMethod } from "../../actions/services/service-extension";

import { ConnectionSummary, ConnectionSettingsActions } from "../../components/connections-settings";
import { uploadAtto, generateActOfEntrustment, generateActOfEntrustmentReset } from "../../actions/certification";
import { getSdiConfig } from "../../actions/services/sdi";

import { fetchCctProvisioningInfo } from "../../actions/services/cct/provisioning";

import Warning from "../../components/connections-settings/connections-setting-warnings";
import { getItemService, getItemServices } from "../../utils/state-utils/services";
import { errorBoundary } from "../../components/general/error-boundary";
import { connectionsWithoutService } from "../../utils/constant";
import { useFeature } from "../../hooks/use-feature";

export class ConnectionSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initial: {
                serviceSharing: {
                    share: undefined,
                    signatureType: "NO_SIGN"
                }
            },
            serviceSharing: {
                share: undefined,
                signatureType: "NO_SIGN"
            }
        };
    }

    static propTypes = {
        activeServices: PropTypes.array,
        actOfEntrustment: PropTypes.string,
        actOfEntrustmentStatus: PropTypes.object,
        agyoServices: PropTypes.object,
        certification: PropTypes.object,
        certificationInfo: PropTypes.func,
        companies: PropTypes.object,
        company: PropTypes.object,
        connection: PropTypes.object,
        generateActOfEntrustment: PropTypes.func,
        generateActOfEntrustmentReset: PropTypes.func,
        getCompanies: PropTypes.func,
        getConnection: PropTypes.func,
        getConnectionStatus: PropTypes.object,
        match: PropTypes.object,
        companyService: PropTypes.array,
        uploadAtto: PropTypes.func,
        user: PropTypes.object,
        resetFetchItemServices: PropTypes.func,
        sdiConfig: PropTypes.object
    };

    componentDidMount() {
        const { certificationInfo, getConnection, match } = this.props;
        const id = match.params.id;
        getConnection(id);
        certificationInfo(id);
    }

    componentDidUpdate(prevProps) {
        const {
            companies,
            company,
            connection,
            fetchItemServices,
            fetchItemServicesStatus,
            getCompanies,
            otherCompanyService,
            arcServicesList,
            getConnectionStatus,
            getSdiConfig,
            otherCompanyId,
            sdiConfig,
            sdiConfigLoadingStatus,
            fetchCctProvisioningInfo,
            findOwnManagedConnections,
            isCctActive
        } = this.props;

        const { initial, serviceSharing } = this.state;

        if (prevProps.getConnectionStatus.started && getConnectionStatus.ended) {
            if (!companies.data[connection.managedId]) {
                getCompanies(null, 0, 1, null, [connection.managedId]);
            }

            fetchItemServices(false, [company.base.id, otherCompanyId]);

            if (connection.serviceId === "SDI-FLOW") {
                getSdiConfig(connection.managedId);
            }

            if (connection.appId === "EIP" || connection.appId === "CRAM" || connection.appId === "SIG") {
                isCctActive && fetchCctProvisioningInfo(company.base.id);
                findOwnManagedConnections(
                    {
                        active: true,
                        managerIds: [company.base.id],
                        managedIds: [connection.managedId],
                        deleted: false
                    },
                    { page: 0, size: 5000 }
                );
            }
        }
        if (prevProps.fetchItemServicesStatus.started && fetchItemServicesStatus.ended) {
            const isArchive = connection.serviceId === "ARC";
            const sharingService =
                otherCompanyService !== undefined &&
                otherCompanyService.owner !== undefined &&
                //only if the connection type is ARC the owner of the service can be managed
                (!otherCompanyService.owner || isArchive) &&
                otherCompanyService.ownerId !== "" &&
                (otherCompanyService.ownerId === company.base.id || arcServicesList.length > 1);

            this.setState({
                initial: {
                    serviceSharing: {
                        ...initial.serviceSharing,
                        share: sharingService
                    }
                },
                serviceSharing: {
                    ...serviceSharing,
                    share: sharingService
                }
            });
        }

        if (prevProps.sdiConfigLoadingStatus.started && sdiConfigLoadingStatus.ended) {
            const signatureType = sdiConfig ? sdiConfig.signatureType : "NO_SIGN";
            this.setState({
                ...this.state,
                initial: {
                    serviceSharing: {
                        ...initial.serviceSharing,
                        signatureType
                    }
                },
                serviceSharing: {
                    ...this.state.serviceSharing,
                    signatureType
                }
            });
        }
    }

    componentWillUnmount() {
        const { resetFetchItemServices } = this.props;
        resetFetchItemServices();
    }

    serviceSharingChanged = serviceSharing => {
        this.setState({ serviceSharing });
    };

    onSave() {
        const { connection, getExtendServiceMethod, getDeleteServiceExtensionMethod, companies, useMeteringPackage } =
            this.props;
        const { serviceSharing } = this.state;

        let extraData = {};

        if (connection.serviceId === "SDI-FLOW") {
            extraData = { signatureType: serviceSharing.signatureType };
        }

        let newExtensionService =
            ["BANKLINK-POST", "BANKLINK-INFO"].includes(connection.serviceId) || useMeteringPackage;

        let ownerId = newExtensionService ? companies.data[connection.managerId].item.base.uuid : connection.managerId;
        let id = newExtensionService ? companies.data[connection.managedId].item.base.uuid : connection.managedId;

        if (serviceSharing.share) {
            getExtendServiceMethod(newExtensionService, id, ownerId, connection.serviceId, extraData);
        } else {
            getDeleteServiceExtensionMethod(newExtensionService, id, ownerId, connection.serviceId, extraData);
        }
    }

    render() {
        const {
            actOfEntrustment,
            actOfEntrustmentStatus,
            agyoServices,
            cctProvisioningInfo,
            companies,
            company,
            certification,
            connection,
            fetchItemServicesStatus,
            generateActOfEntrustment,
            generateActOfEntrustmentReset,
            getConnectionStatus,
            itemConnections,
            match,
            myCompanyService,
            otherCompanyService,
            otherCompanyServiceIsActive,
            uploadAtto,
            user,
            uploadingAtto
        } = this.props;

        const { initial, serviceSharing } = this.state;

        /**
         * check if connection's serviceId is SDI
         */
        let isSdiConnection = false;
        let isDigitalBoxConnection = false;
        let isErcvConnection = false;
        let isArchiveConnection = false;
        if (connection) {
            isSdiConnection = connection.serviceId === "SDI-FLOW";
            isDigitalBoxConnection = connection.serviceId === "DIGITALBOX";
            isErcvConnection = connection.featureCode === "ERCV";
            isArchiveConnection = connection.serviceId === "ARC";
        }

        const spinning = getConnectionStatus.started || fetchItemServicesStatus.started;

        const isDeleted = connection.status && connection.status.deleted;

        const isAllowedToManage =
            isDeleted ||
            (user.user.roles &&
                user.user.roles.find(r => {
                    return r.resourceId === connection.managedId;
                }) !== undefined);

        let hasCctProvisioning = false;

        if (
            cctProvisioningInfo[company.base.id] &&
            cctProvisioningInfo[company.base.id].couponId === "1000" &&
            cctProvisioningInfo[company.base.id].status === "validated"
        ) {
            hasCctProvisioning = true;
        }

        const hasContract =
            itemConnections &&
            itemConnections.connections.some(c =>
                ["AWAITING_APPROVAL", "CERTIFIED"].includes(c.status.certificationStatus)
            );
        const needsContract =
            connection.status &&
            (connection.status.status !== "VALIDATED" ||
                (connection.status.certificationStatus !== "CERTIFIED" &&
                    connection.status.certificationStatus !== "AWAITING_APPROVAL"));

        let managedServiceIsReadOnly = otherCompanyService.readonly;

        managedServiceIsReadOnly = connection.status && !connection.status.deleted && managedServiceIsReadOnly;

        const connectionHasService = !connectionsWithoutService.includes(connection.serviceId);

        return (
            <Spin spinning={spinning}>
                <div className="c-connections-settings">
                    <p>
                        <button className="c-link-button" onClick={() => this.props.history.goBack()}>
                            <Icon type="left" />
                            <FormattedMessage id="general.connections" />
                        </button>{" "}
                        \ <FormattedMessage id="general.settings" />
                    </p>
                    <Warning
                        isAllowedToManage={isAllowedToManage}
                        needsContract={needsContract}
                        isSpinning={spinning}
                        managerServiceIsActive={myCompanyService.agyoService !== undefined || !connectionHasService}
                        managerOwnsService={
                            !connectionHasService ||
                            (myCompanyService.ownerId !== undefined && myCompanyService.ownerId === company.base.id)
                        }
                        managedAlreadyHasService={
                            otherCompanyService.ownerId !== undefined &&
                            otherCompanyService.ownerId !== company.base.id &&
                            !managedServiceIsReadOnly &&
                            !isArchiveConnection
                        }
                    />
                    <ConnectionSummary
                        description={
                            connection.serviceId &&
                            agyoServices.services.find(s => s.id === connection.serviceId) &&
                            agyoServices.services.find(s => s.id === connection.serviceId).description
                        }
                        certification={certification}
                        connection={connection}
                        isAllowedToManage={isAllowedToManage}
                    />
                    <ConnectionSettingsActions
                        actOfEntrustment={actOfEntrustment}
                        actOfEntrustmentStatus={actOfEntrustmentStatus}
                        companies={companies}
                        company={company}
                        connection={connection}
                        connectionId={match.params.id}
                        companyDescription={connection.managedDescription}
                        generateActOfEntrustment={generateActOfEntrustment}
                        generateActOfEntrustmentReset={generateActOfEntrustmentReset}
                        hasCctProvisioning={hasCctProvisioning}
                        hasContract={hasContract}
                        isAllowedToManage={isAllowedToManage && connectionHasService}
                        isDigitalBoxConnection={isDigitalBoxConnection}
                        isExtensionActive={
                            (myCompanyService.ownerId === undefined || myCompanyService.owner) &&
                            (otherCompanyService.ownerId === undefined ||
                                otherCompanyService.ownerId === company.base.id)
                        }
                        isSelectedCompanyManager={connection.managerId === company.base.id}
                        isSdiConnection={isSdiConnection}
                        isErcvConnection={isErcvConnection}
                        isSpinning={spinning}
                        serviceSharing={serviceSharing}
                        onServiceShareChanged={value => this.serviceSharingChanged(value)}
                        needsContract={needsContract}
                        otherCompanyServiceIsActive={otherCompanyServiceIsActive}
                        onSave={() => this.onSave()}
                        serviceSharingChanged={
                            initial.serviceSharing.share !== serviceSharing.share ||
                            initial.serviceSharing.signatureType !== serviceSharing.signatureType
                        }
                        uploadCert={uploadAtto}
                        user={user}
                        uploadingAtto={uploadingAtto}
                        managedServiceIsReadOnly={managedServiceIsReadOnly}
                    />
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = (state, props) => {
    const selectedCompany = state.companies.data[props.match.params.item]
        ? state.companies.data[props.match.params.item].item
        : {};
    const connection = state.connectionsNew.read.getConnection.result;
    const itemServices = state.services.servicesSubscription.itemServices.services;
    let otherCompanyService = {};
    const services = state.services.active.services;
    const agyoServices = state.services.agyoServices;

    let otherCompanyServiceIsActive = undefined;
    let myCompanyServiceIsActive = undefined;
    let sdiConfig = undefined;

    const otherCompany = state.companies.data[props.match.params.item]
        ? state.companies.data[props.match.params.item].item
        : {};

    const otherCompanyId = otherCompany.base.id === connection.managedId ? connection.managerId : connection.managedId;
    const arcServicesList = getItemServices(state, otherCompanyId).filter(
        s => !s.readonly && s.agyoService.id === "ARC"
    );

    let myCompanyService = {};

    if (connection.connections[0]) {
        myCompanyServiceIsActive = services.includes(connection.connections[0].serviceId);

        otherCompanyService = getItemService(state, otherCompanyId, connection.connections[0].serviceId, {});

        otherCompanyServiceIsActive =
            otherCompanyService.agyoService !== undefined ? otherCompanyService.agyoService.active : false;

        myCompanyService = getItemService(state, props.match.params.item, connection.connections[0].serviceId, {});

        sdiConfig = state.services.sdi.configs[connection.managedId];
    }

    return {
        agyoServices,
        actOfEntrustment: state.certification.actOfEntrustment.data,
        actOfEntrustmentStatus: state.certification.actOfEntrustment.status,
        certification: state.certification,
        company: selectedCompany,
        companies: state.companies,
        connection: connection.connections && {
            managedId: connection.managedId,
            managerId: connection.managerId,
            managedDescription: connection.managedDescription,
            managerDescription: connection.managerDescription,
            status: {},
            ...connection.connections[0]
        },
        itemConnections: state.connectionsNew.read.findOwnManagedConnections.content[0],
        getConnectionStatus: state.connectionsNew.read.getConnection.status,
        services: services,
        fetchItemServicesStatus: state.services.servicesSubscription.itemServices.status,
        itemServices: itemServices,
        otherCompanyId: otherCompanyId,
        otherCompanyService: otherCompanyService,
        myCompanyService: myCompanyService,
        myCompanyServiceIsActive: myCompanyServiceIsActive,
        otherCompanyServiceIsActive: otherCompanyServiceIsActive,
        sdiConfig: sdiConfig,
        arcServicesList: arcServicesList,
        sdiConfigLoadingStatus: state.services.sdi.status,
        user: state.user,
        uploadingAtto: state.certification.uploadAtto.status.started,
        cctProvisioningInfo: state.services.cct.provisioningInfo.provisioningInfo,
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC")
    };
};

const actions = {
    certificationInfo,
    getConnection,
    getSdiConfig,
    findOwnManagedConnections,
    fetchItemServices,
    generateActOfEntrustment,
    generateActOfEntrustmentReset,
    getCompanies,
    getExtendServiceMethod,
    getDeleteServiceExtensionMethod,
    uploadAtto,
    resetFetchItemServices,
    fetchCctProvisioningInfo
};

const composedHoc = compose(connect(mapStateToProps, actions), injectIntl, errorBoundary);

export default composedHoc(props => (
    <ConnectionSettingsView {...props} useMeteringPackage={useFeature("USE_METERING_PACKAGE")} />
));
