import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";

import { deleteOffice, disableOfficeReset } from "../../../actions/company/offices";
import { loadCompany } from "../../../actions/company";
import { errorBoundary } from "../../../components/general/error-boundary";
import Offices from "../../../components/registry/offices";
import "../style.css";
import PageTitle from "../../../components/general/page-title";

class OfficesView extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        deleteOffice: PropTypes.func.isRequired,
        deleteOfficeStatus: PropTypes.object.isRequired,
        disableOfficeReset: PropTypes.func.isRequired,
        loadCompany: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired
    };

    render() {
        const { company, deleteOffice, deleteOfficeStatus, disableOfficeReset, loadCompany, user } = this.props;
        return (
            <div className="registry-view">
                <PageTitle
                    title={<FormattedMessage id="general.offices" />}
                    subtitle={<FormattedMessage id="c-registry-view-office" />}
                    onBack={() => this.props.history.goBack()}
                />
                <Offices
                    company={company}
                    deleteOffice={deleteOffice}
                    deleteOfficeStatus={deleteOfficeStatus}
                    disableOfficeReset={disableOfficeReset}
                    history={this.props.history}
                    loadCompany={loadCompany}
                    user={user}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    deleteOfficeStatus: state.offices.deleteOffice.status,
    updateItemStatus: state.companies.updateItem.status,
    user: state.user.user
});

const actions = {
    deleteOffice,
    disableOfficeReset,
    loadCompany
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(OfficesView);
