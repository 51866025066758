import React from "react";
import { DEADLINE_APP_ID } from "../../../config";
import PortaleChildApp from "../../portale-child-app";
import { connect } from "react-redux";

const DeadlinesView = ({ workspaceId }) => {
    return (
        <PortaleChildApp
            appId="DEADLINE"
            serviceId="rnd:comic:payments-incomes:app"
            deepLinkParameters={{
                tenantId: workspaceId,
                workspaceId: workspaceId,
                appId: DEADLINE_APP_ID
            }}
        />
    );
};

const mapStateToProps = state => ({
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null
});

export default connect(mapStateToProps)(DeadlinesView);
