import React from "react";

import { Route } from "react-router-dom";

import Detail from "../../../components/apps/detail";
import CookiePolicy from "../../../components/cookie-policy";
import { errorBoundary } from "../../../components/general/error-boundary";
import { ENABLE_WEB_NOTIFICATIONS, OTC_INVOICE_TRADING_WEBAPP_ENABLED } from "../../../config";
import { useFeature } from "../../../hooks/use-feature";
import { UsersManagementContextProvider } from "../../../user-context";
import Apps from "../../apps";
import Adempimenti from "../../child-apps/adempimenti";
import AgroView from "../../child-apps/agro";
import AnalyticsView from "../../child-apps/analytics";
import AntiMoneyLaunderingView from "../../child-apps/anti-money-laundering";
import Archive from "../../child-apps/archive";
import BusinessInformationView from "../../child-apps/business-information";
import ConsoleFatturazione from "../../child-apps/console-fatturazione";
import CramFrontWebapp from "../../child-apps/cram-front-webapp";
import CustomerManagementChildApp from "../../child-apps/customer-management";
import DeadlinesView from "../../child-apps/deadlines";
import DragWebapp from "../../child-apps/drag";
import DeepTierView from "../../child-apps/deep-tier";
import Firma from "../../child-apps/firma";
import FirmaBatchId from "../../child-apps/firma/batch";
import Gdpr from "../../child-apps/gdprlog";
import NiumaBonusView from "../../child-apps/niumabonus";
import OtcInvoiceTradingWebapp from "../../child-apps/otc-invoice-trading-webapp";
import PecManagerView from "../../child-apps/pecmanager";
import PeopleApp from "../../child-apps/people-app";
import Privacy from "../../child-apps/privacy";
import SpendManagementView from "../../child-apps/spendmanagement";
import SpidWebapp from "../../child-apps/spid-webapp";
import TshChildApp from "../../child-apps/tsh";
import StudioHrView from "../../child-apps/studio-hr";
import Connections from "../../connections";
import ConnectionsCreateExistingCompany from "../../connections-create-existing-company";
import ConnectionsCreateNewCompany from "../../connections-create-new-company";
import ConnectionSetting from "../../connections-settings";
import Cdc from "../../console-digitale";
import CreateApiKey from "../../create-api-key";
import CreateApiKeySuccess from "../../create-api-key-success";
import CreateUserSuccess from "../../create-user-success";
import Dashboard from "../../dashboard";
import EditApiKey from "../../edit-api-key";
import EditApiKeySuccess from "../../edit-api-key-success";
import EditUserDetailsView from "../../edit-user-details";
import EditUserSuccess from "../../edit-user-success";
import ItemEdit from "../../item-edit";
import ItemEditSuccess from "../../item-edit/success";
import { Metering } from "../../metering";
import NotificationsView from "../../notifications";
import NotificationsCenterView from "../../notifications-center";
import OfficesCreate from "../../offices/create";
import OfficesEdit from "../../offices/edit";
import OfficesSuccess from "../../offices/success";
import ApiKey from "../../registry/apikey";
import Intermediary from "../../registry/intermediary";
import Office from "../../registry/office";
import UsersManage from "../../registry/user/new";
import UsersManageOld from "../../registry/user-old/new";
import SelectedUserView from "../../selected-user";
import SelectedUserViewOld from "../../selected-user-old";
import Accounting from "../../services/accounting";
import Billing from "../../services/billing";
import BudgetView from "../../services/budget";
import CdcView from "../../services/console-digitale";
import ExpenseReports from "../../services/expense-reports";
import Fulfillments from "../../services/fulfillments";
import GdprLog from "../../services/gdpr-log";
import InvoiceTradingView from "../../services/invoice-trading";
import PeopleAppView from "../../services/people-app";
import PrivacyView from "../../services/privacy";
import Signature from "../../services/signature";
import Storage from "../../services/storage";
import ConfigureItemView from "../../services/storage/configure-item";
import ConfigureItemServicesView from "../../services/storage/configure-item-services";
import ConfigureManagedItemsView from "../../services/storage/configure-managed-items";
import ConfiguredManagedItemsPersonalView from "../../services/storage/configure-managed-items-personal";
import EditCctView from "../../services/storage/edit-cct";
import GenerateCctDocument from "../../services/storage/generate-cct-document";
import TsPayView from "../../services/tspay";
import UserCreationFlow from "../../user-creation-flow";
import UserInfoView from "../../user-info";
import ValidateCompany from "../../validate-company";
import ValidateCompanySuccess from "../../validate-company-success";
import IntermediaryChildApp from "../../child-apps/intermediary";

const RoutesComponent = () => {
    const newCustomerManagement = useFeature("NEW_CUSTOMER_MANAGEMENT");
    const NEW_USER_MANAGEMENT = useFeature("NEW_USER_MANAGEMENT");

    return (
        <React.Fragment>
            <Route exact path="/:item/dashboard" component={Dashboard} />
            {/*CONNECTIONS*/}
            <Route exact path="/:item/connections/:type?/:history?" component={Connections} />
            <Route
                path="/:item/connections/create/existingCompany/:companyId?"
                component={ConnectionsCreateExistingCompany}
            />
            <Route path="/:item/connections/create/newCompany" component={ConnectionsCreateNewCompany} />
            {newCustomerManagement ? (
                <Route exact path="/:item/customer-management" component={CustomerManagementChildApp} />
            ) : null}
            <Route path="/:item/connection/:id" component={ConnectionSetting} />
            <Route path="/:item/notifications/:id?" component={NotificationsView} />
            <Route exact path="/:item/edit" component={ItemEdit} />
            <Route exact path="/:item/edit/:editlogo" component={ItemEdit} />
            <Route exact path="/:item/edit/success" component={ItemEditSuccess} />
            <Route exact path="/:item/edit-user-details" component={EditUserDetailsView} />
            <Route exact path="/:item/apps" component={Apps} />
            <Route path="/:item/apps/:detail/details" component={Detail} />
            <Route path="/:item/apps/gdprlog" component={Gdpr} />
            <Route exact path="/:item/apps/firma" component={Firma} />
            <Route exact path="/:item/apps/firma/list/batchId/:batchId" component={FirmaBatchId} />
            <Route exact path="/:item/apps/adempimenti" component={Adempimenti} />
            <Route exact path="/:item/apps/privacy" component={Privacy} />
            <Route exact path="/:item/apps/people-app" name="people-app" component={PeopleApp} />
            <Route exact path="/:item/apps/fatturazione" component={ConsoleFatturazione} />
            {OTC_INVOICE_TRADING_WEBAPP_ENABLED ? (
                <Route exact path="/:item/apps/invoice-trading" component={OtcInvoiceTradingWebapp} />
            ) : null}
            <Route exact path="/:item/apps/cram-front" component={CramFrontWebapp} />
            <Route exact path="/:item/apps/spendmanagement" component={SpendManagementView} />
            <Route exact path="/:item/apps/agro" component={AgroView} />
            <Route exact path="/:item/apps/digitalbox" component={Cdc} />
            <Route exact path="/:item/apps/drag" component={DragWebapp} />
            <Route exact path="/:item/apps/analytics" component={AnalyticsView} />
            <Route exact path="/:item/apps/pecmanager" component={PecManagerView} />
            <Route exact path="/:item/apps/aml" component={AntiMoneyLaunderingView} />
            <Route exact path="/:item/apps/niumabonus" component={NiumaBonusView} />
            <Route exact path="/:item/apps/spid" component={SpidWebapp} />
            <Route exact path="/:item/apps/tsh" component={TshChildApp} />
            <Route exact path="/:item/apps/hr" component={StudioHrView} />
            <Route exact path="/:item/apps/archive" component={Archive} />
            <Route exact path="/:item/apps/deadlines" component={DeadlinesView} />
            <Route exact path="/:item/apps/deeptier" component={DeepTierView} />
            <Route exact path="/:item/apps/business-information" component={BusinessInformationView} />
            <Route path="/:item/company/validate" exact component={ValidateCompany} />
            <Route path="/:item/company/validate/success" component={ValidateCompanySuccess} />
            {/*SERVICES*/}
            <Route path="/:item/services/signature" component={Signature} />
            <Route path="/:item/services/fulfillments" component={Fulfillments} />
            <Route path="/:item/services/billing" component={Billing} />
            <Route exact path="/:item/services/storage/edit-cct" component={EditCctView} />
            <Route path="/:item/services/storage/edit-cct/configure-item" component={ConfigureItemView} />
            <Route
                path="/:item/services/storage/edit-cct/configure-item-services"
                component={ConfigureItemServicesView}
            />
            <Route
                path="/:item/services/storage/edit-cct/configure-managed-items-personal"
                component={ConfiguredManagedItemsPersonalView}
            />
            <Route
                path="/:item/services/storage/edit-cct/configure-managed-items"
                component={ConfigureManagedItemsView}
            />
            <Route
                path="/:item/services/storage/edit-cct/generate-document/:managedItem"
                component={GenerateCctDocument}
            />
            <Route exact path="/:item/services/storage" component={Storage} />
            <Route path="/:item/services/gdprlog" component={GdprLog} />
            <Route path="/:item/services/accounting" component={Accounting} />
            <Route path="/:item/services/expense-reports" component={ExpenseReports} />
            <Route path="/:item/services/privacy" component={PrivacyView} />
            <Route path="/:item/services/console-digitale" component={CdcView} />
            <Route path="/:item/metering" component={Metering} />
            <Route path="/:item/services/people-app" component={PeopleAppView} />
            <Route path="/:item/services/budget" component={BudgetView} />
            <Route path="/:item/services/invoice-trading" component={InvoiceTradingView} />
            <Route path="/:item/services/tspay" component={TsPayView} />

            {/*REGISTRY*/}

            <Route exact path="/:item/registry/apikey" component={ApiKey} />
            <Route path="/:item/registry/office/create" component={OfficesCreate} exact />
            <Route path="/:item/registry/office/create/success" component={OfficesSuccess} />
            <Route path="/:item/registry/office/:id/edit" component={OfficesEdit} exact />
            <Route path="/:item/registry/office/:id/edit/success" component={OfficesSuccess} />
            <Route path="/:item/registry/office" component={Office} exact />
            <Route path="/:item/registry/intermediary" component={Intermediary} />
            <Route path="/:item/registry/user/edit/success" component={EditUserSuccess} />
            <Route path="/:item/intermediary" component={IntermediaryChildApp} />

            <UsersManagementContextProvider>
                <Route
                    path="/:item/registry/user/:selectedUserId/:action"
                    component={NEW_USER_MANAGEMENT ? SelectedUserView : SelectedUserViewOld}
                />
                <Route exact path="/:item/registry/user/create" component={UserCreationFlow} />
                <Route
                    exact
                    path="/:item/registry/user"
                    component={NEW_USER_MANAGEMENT ? UsersManage : UsersManageOld}
                />
            </UsersManagementContextProvider>

            <Route exact path="/:item/registry/apikey/create" component={CreateApiKey} />
            <Route exact path="/:item/registry/apikey/create/success" component={CreateApiKeySuccess} />
            <Route exact path="/:item/registry/apikey/edit/success" component={EditApiKeySuccess} />
            <Route exact path="/:item/registry/apikey/:selectedApiKey/edit" component={EditApiKey} />

            <Route path="/:item/registry/user/create/success" component={CreateUserSuccess} />
            {/** FIXME da spostare a livello per la visualizzazione senza azienda selezionata */}
            <Route path="/:item/cookiepolicy" component={CookiePolicy} />
            <Route path="/:item/registry/userinfo/:userId" component={UserInfoView} />
            {ENABLE_WEB_NOTIFICATIONS ? (
                <Route path="/:item/notifications-center" component={NotificationsCenterView} />
            ) : null}
        </React.Fragment>
    );
};

export const Routes = errorBoundary(RoutesComponent);
