import styled from "styled-components";
import { TS_DIGITAL_IMAGES } from "../../config";

export const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const Header = styled.header`
    height: 4rem;
    background-color: ${props => props.theme.colors.text.yankeesBlue};
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    position: relative;
    color: ${props => props.theme.colors.background.white};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`;

export const Sidebar = styled.div`
    width: 35%;
    min-height: calc(100vh - 4rem);
    background-color: ${props => props.theme.colors.background.white};
    padding: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
        width: 100%;
        padding: 2.5rem 1rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 40%;
        padding: 2.5rem;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        width: 35%;
        padding: 2.5rem;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        width: 35%;
        padding: 3.5rem;
    }

    @media (min-width: 1920px) {
        width: 30%;
        padding: 3.5rem 5rem;
    }
`;

export const AdvertisingContainer = styled.div`
    width: 65%;
    height: calc(100vh - 4rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${TS_DIGITAL_IMAGES}prelogin/bgblue.svg);
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 991px) {
        display: none;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 60%;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        width: 65%;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        width: 65%;
    }

    @media (min-width: 1920px) {
        width: 70%;
    }
`;

export const LogoContainer = styled.div`
    width: 165px;

    @media (max-width: 992px) {
        margin: 0 auto;
    }
`;

export const Logo = styled.img`
    width: 100%;
`;

export const Title = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 2.5rem;
    font-family: Cairo;
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 3rem;
    margin-bottom: 1rem;

    @media (max-width: 1439px) {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: initial;
    }
`;

export const LoginContainer = styled.div`
    margin-top: 8rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        max-width: 374px;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 1.125rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.14px;
    line-height: 24px;
    margin-bottom: 0.625rem;
`;

export const Description = styled.div`
    color: ${props => props.theme.colors.text.cadet};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
`;

export const InfoContainer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 1rem;
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    letter-spacing: 0.25px;
    line-height: 1.5rem;
    font-weight: normal;
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
`;

export const InfoIcon = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 1rem;
`;

export const AdvertisingBox = styled.div`
    max-width: 869px;
    background: linear-gradient(360deg, rgba(243, 246, 249, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
    border-radius: 0.5rem;
    padding: 4rem;
    margin: 2rem;
    position: relative;
`;

export const Image = styled.img`
    position: absolute;
    right: -140px;
    bottom: 2.5rem;
    width: 357px;

    @media (max-width: 1240px) {
        display: none;
    }

    @media (min-width: 1241px) and (max-width: 1320px) {
        right: -32px;
        width: 264px;
    }

    @media (min-width: 1321px) and (max-width: 1733px) {
        right: -40px;
        width: 340px;
    }
`;
