import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { NEW_CUSTOMERMANAGEMENT_APP_ID } from "../../../config";
import { useFeature } from "../../../hooks/use-feature";
import PortaleChildApp from "../../portale-child-app";

const CustomerManagementChildApp = ({ workspaceId, item }) => {
    const newCustomerManagement = useFeature("NEW_CUSTOMER_MANAGEMENT");

    return newCustomerManagement ? (
        <PortaleChildApp
            appId="WORKSPACE"
            serviceId="WORKSPACE"
            deepLinkParameters={{
                tenantId: workspaceId,
                appId: NEW_CUSTOMERMANAGEMENT_APP_ID
            }}
        />
    ) : (
        <Redirect to={`/${item.base.id}/dashboard`} />
    );
};

const mapStateToProps = (state, props) => ({
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null,
    item: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {}
});

export default connect(mapStateToProps)(CustomerManagementChildApp);
