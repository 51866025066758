import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import get from "lodash.get";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router";
import { compose } from "redux";

import { findOwnManagerConnections, findOwnManagerConnectionsReset } from "../../actions/connections/read";
import { errorBoundary } from "../../components/general/error-boundary";
import store from "../../reducers";
import { refreshToken } from "../../utils/auth-utils";
import { getCurrentCookieConsents } from "../../utils/cookie-consent-utils";
import { ChildApp, SpinnerContainer } from "./styled";
import { useMemo } from "react";
import { useCallback } from "react";

function hideElevioLauncher() {
    if (window._elev) {
        window._elev.setSettings({
            hideLauncher: true
        });
    }
}

function showElevioLauncher() {
    if (window._elev) {
        window._elev.setSettings({
            hideLauncher: false
        });
    }
}

function hideUservoiceLauncher() {
    const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
    if (uservoiceLauncher) {
        uservoiceLauncher.style.visibility = "hidden";
    }
}

function showUservoiceLauncher() {
    const uservoiceLauncher = document.querySelector(".uv-icon.uv-bottom-right");
    if (uservoiceLauncher) {
        uservoiceLauncher.style.visibility = "visible";
    }
}

function showElevioWidget() {
    showElevioLauncher();
    showUservoiceLauncher();
}

function hideElevioWidget() {
    hideElevioLauncher();
    hideUservoiceLauncher();
}

const PortaleChildApp = ({
    auth,
    appId,
    className,
    connections,
    connectionLoadingStatus,
    customLaunchData,
    disableDataPropagation,
    findOwnManagerConnections,
    findOwnManagerConnectionsReset,
    hideElevioAndUservoice,
    item,
    refreshToken,
    serviceId,
    url,
    useOldLaunchDataFormat,
    user,
    isLoading,
    deepLink,
    deepLinkParameters
}) => {
    // If both serviceId and appId have been provided, and the user is a MANAGER for the current item, we need to find
    // out the owner of the service via a combination of the user's roles and the data returned from the connections.
    // This is needed to properly give OneFront applications the itemId and ownerId parameters.
    const { item: selectedItemId } = useParams();
    const isManager = user.roles.filter(r => r.resourceId === selectedItemId).some(r => r.actionKey === "MANAGER");
    useEffect(() => {
        if (isManager && appId && serviceId) {
            findOwnManagerConnections({ active: true, managedIds: [selectedItemId] }, { page: 0 });
        }

        return () => findOwnManagerConnectionsReset();
    }, [appId, findOwnManagerConnections, findOwnManagerConnectionsReset, isManager, selectedItemId, serviceId]);

    useEffect(() => {
        // When unmounting the ts-portale should show its Elevio launcer and its
        // UserVoice icon again
        return () => showElevioWidget();
    }, []);

    const launchData = useMemo(() => {
        let launchData = {};
        if (useOldLaunchDataFormat) {
            launchData = {
                cookieConsents: getCurrentCookieConsents(),
                accessToken: get(auth, "loginAuth.securityToken"),
                refreshToken: get(auth, "loginAuth.refreshToken"),
                userUuid: get(user, "profile.uuid"),
                elevioUserHash: user.elevioUserHash,
                email: get(user, "profile.email"),
                itemId: get(item, "base.id"),
                itemUuid: get(item, "base.uuid"),
                itemClassifier: get(item, "base.details.classifier"),
                itemDescription: get(item, "base.details.description"),
                language: get(user, "profile.language"),
                taxId: get(item, "base.identifier.taxId"),
                vatNumber: get(item, "base.identifier.vatNumber")
            };
        } else {
            launchData = {
                cookieConsents: getCurrentCookieConsents(),
                item: {
                    id: get(item, "base.uuid"),
                    classifier: get(item, "base.details.classifier"),
                    description: get(item, "base.details.description"),
                    taxId: get(item, "base.identifier.taxId"),
                    vatNumber: get(item, "base.identifier.vatNumber"),
                    ncsId: get(item, "base.ncsId"),
                    taxRegion: get(item, "base.identifier.taxRegion")
                },
                settings: {
                    language: get(user, "profile.language")
                },
                user: {
                    email: get(user, "profile.email"),
                    firstName: get(user, "profile.firstName"),
                    id: get(user, "profile.uuid"),
                    lastName: get(user, "profile.lastName"),
                    ncsId: get(user, "profile.ncsId"),
                    token: get(auth, "loginAuth.securityToken")
                }
            };
        }

        return { ...launchData, ...customLaunchData };
    }, [auth, item, customLaunchData, useOldLaunchDataFormat, user]);

    const suppliedMethods = useMemo(() => {
        return {
            // We're using the store directly as passing new versions of this call
            // doesn't update the supplied methods, we need a way to always be able to access the current store value
            getNewAccessToken: () => refreshToken(store.getState().auth)
        };
    }, [refreshToken]);

    const onLaunched = useCallback(() => {
        // The child app may have its own Elevio and UserVoice
        // launchers. When it does, we hide the ts-portale ones
        if (hideElevioAndUservoice) {
            hideElevioWidget();
        }
    }, [hideElevioAndUservoice]);

    // If parent or connection are still loading wait until it finished loading
    // So the data we will pass to the child iframe will be persistent
    const needsToLoadConnection = isManager && serviceId && appId && !connectionLoadingStatus.ended;
    if (isLoading || needsToLoadConnection) {
        return (
            <SpinnerContainer>
                <Spin size="large" />
            </SpinnerContainer>
        );
    }

    // Find the first manager on which the current user has at least one role
    // In the case of multiple managers for the app, it shouldn't be that a single user has roles
    // on more than one, but if it happens defaulting to the first matching one should still be acceptable for now.
    const managerId = connections
        .map(c => c.managerUuid)
        .find(manager => user.roles.some(r => r.resourceUuid === manager));

    return (
        <ChildApp
            url={url}
            childOrigin={url}
            disableDataPropagation={disableDataPropagation}
            suppliedMethods={suppliedMethods}
            launchData={launchData}
            launchingPlaceholder={
                <SpinnerContainer>
                    <Spin size="large" />
                </SpinnerContainer>
            }
            onLaunched={onLaunched}
            serviceId={serviceId}
            className={className}
            itemId={item.base.uuid}
            managerId={managerId}
            deepLink={deepLink}
            deepLinkParameters={deepLinkParameters}
        />
    );
};

PortaleChildApp.defaultProps = {
    customLaunchData: {},
    useOldLaunchDataFormat: false,
    isLoading: false
};

PortaleChildApp.propTypes = {
    // AppId for the rendered application, used to find the manager
    appId: PropTypes.string,
    auth: PropTypes.object,
    customLaunchData: PropTypes.object,
    // Used to disable microfrontends communication
    disableDataPropagation: PropTypes.bool,
    hideElevioAndUservoice: PropTypes.bool,
    item: PropTypes.object,
    url: PropTypes.string,
    useOldLaunchDataFormat: PropTypes.bool,
    user: PropTypes.object,
    deepLink: PropTypes.string,
    deepLinkParameters: PropTypes.object
};

const mapStateToProps = (state, props) => ({
    auth: state.auth,
    item: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
    connections: state.connectionsNew.read.findOwnManagerConnections.content,
    connectionLoadingStatus: state.connectionsNew.read.findOwnManagerConnections.status,
    user: state.user.user
});

const composeHoc = compose(
    withRouter,
    connect(mapStateToProps, dispatch => ({
        refreshToken: auth => refreshToken(auth, dispatch),
        findOwnManagerConnections: (filter, params) => dispatch(findOwnManagerConnections(filter, params)),
        findOwnManagerConnectionsReset: () => dispatch(findOwnManagerConnectionsReset())
    })),
    errorBoundary
);

export default composeHoc(PortaleChildApp);
